import { useEffect} from "react";

import "./popup.css"
import { Image,  Box, Group, Modal, Flex, ScrollArea, Grid } from "@mantine/core";

// import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min.js";

const PopupLaptop = ({project, opened,  closed}) => {
    // useEffect(() => {
    //     require("bootstrap/dist/js/bootstrap.bundle.min.js");
    //   }, []);
    useEffect(()=>{
    }, [])

    return (
        <Modal opened={opened} onClose={closed} size="100%"  withCloseButton={false} overlayProps={{
            backgroundOpacity: 0.75,
            blur: 3,
        }} padding={0} centered scrollAreaComponent={ScrollArea.Autosize}  >
            <Image src={project.path} alt={project.name} opacity={0.2} pos={"absolute"} className="tw-h-full tw-w-full -tw-z-10 tw-pl-0"  left={0} top={0} bottom={0} right={0}  />
            {/* <div className="tw-bg-black tw-absolute tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-opacity-100 tw-z-10"></div> */}
            <Flex className="tw-pt-5 tw-z-100 tw-ml-5 tw-mr-5" align={"center"}>
                <h1 className="modal-title  tw-text-[36px] textLinear titilliumWeb tw-pb-0 tw-bg-black" id="exampleModalLabel">{project !== undefined ? project.nameProject :""}</h1>
                <Modal.CloseButton/>
            </Flex>
            <Flex direction={"column"} justify={"space-between"} className="tw-mb-10 tw-z-30 tw-opacity-100 tw-m-5">
                    <h2 className="modal-title  tw-text-[26px] textLinear titilliumWeb tw-mb-4" > {project !== undefined ? project.name :""}</h2>
                    <div>
                        {project !== undefined && project.category !== undefined ? project.category.map((v,k) => {
                            return <button className="tw-btn tw-btn-outline tw-rounded-full tw-underline tw-border-white  tw-text-white tw-mr-2" key={k}>{v.nameCategory}</button>
                        }):""}
                    </div>
                <br></br>
                <Flex className="">
                    <Grid className="tw-w-[80%]">
                        <Grid.Col>
                            <h2 className="tw-text-[26px] textLinear ">{`L'objectif`}</h2>
                            {project !==undefined  ? <span className="tw-text-[14px] tw-text-white">{project.objectives}</span> : ""}
                        </Grid.Col>
                        <Grid.Col>
                            <h2 className="tw-text-[26px] textLinear ">Notre solution</h2>
                            {project !==undefined  ? <span className="tw-text-[14px] tw-text-white">{project.solutions}</span> : ""}
                        </Grid.Col>
                        <Grid.Col>
                            <h2 className="tw-text-[26px] textLinear">Les résultats</h2>
                            {project !==undefined  ? <span className="tw-text-[14px] tw-text-white">{project.results}</span> : ""}
                        </Grid.Col>
                    </Grid>
                    <Flex className="tw-w-[20%]" direction={"column"} justify={"flex-start"} align={"center"}>

                        {
                            project !== undefined && project.numbers !== undefined ?
                            project.numbers.map((value,index)=>{
                                return <Group direction={"column"} key={index} gap={"xl"} align={"flex-start"} >
                                          <Box ml={"xl"} >
  
                                              <div className="textLinear tw-text-[24px] tw-text-center">{value.numberProject}</div>
                                              <div className="tw-text-white tw-font-bold tw-text-[16px] tw-text-center">{value.textNumber}</div>
                                          </Box>
                                      </Group>
                                  }) : ""
                                }
                    </Flex>
                </Flex>    
            </Flex>
            
        </Modal>
    );
}
        
        export default PopupLaptop;
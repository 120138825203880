import { Container } from "react-bootstrap";
import Banner from "../components/banner/Banner";
import CategorySquare from "../components/category/categorySquare/CategorySquare";
import RandomProject from "../components/project/randomProject/RandomProject";
import { ScrollRestoration } from "react-router-dom";

const Main = () => {
    return (
        <div className="tw-w-full">
            <Banner/>
            <RandomProject/>
            <CategorySquare/>
            <ScrollRestoration />
        </div>
    );
}

export default Main;
import { useViewportSize } from "@mantine/hooks";
import RandomProjectLaptop from "./laptop/RandomProjectLaptop";
import RandomProjectMobile from "./mobile/RandomProjectMobile";

const RandomProject = () => {
    const {height, width} = useViewportSize()

    // useEffect(()=>{
    //     // setWidth(window.innerWidth)
    // }, [width])

    return (
                 width > 550 ? <RandomProjectLaptop /> : <RandomProjectMobile/> 
    );
}

export default RandomProject;
import FullListProjects from "../components/project/fullListProjects/FullListProjects";

const Projets = () => {
    return (
        <main className="tw-flex tw-flex-col tw-items-center">
                
            <div className="tw-w-[80%]">
                <FullListProjects/>
            </div>
        </main>
    );
}

export default Projets;
import { useEffect, useState } from "react";
import { getProjectsSQL} from "../ProjetsSQL";
import DisplayCategoryProjects from "./displayCategoryProjects/DisplayCategoryProjects";



const CategoryProjects =   ({filter}) => {

   
    // const data = await getData(filter)

    const [project , setProject] = useState([])


    const getData =  async (filter)=> {
        const val = await getProjectsSQL().then((value)=>{

            // .then((value)=>{
                //     console.log(value)
                let tmp =[]
                for (let i of value.data.data) {
                    if(i.category.filter((v)=>{
                        return v.nameCategory === filter
                    }).length !== 0){
                        tmp.push(i)
                    }
                    // if((i.category.filter((v)=>{
                        //     v.nameCategory == filter
                        // })).length !== 0) {
                            //     tmp.push(i)
                            // }
                        }
                        // console.log(tmp)
                        // console.log(val.data.data.result)
                        return tmp
                    })

                    return val
        // })
        //     console.log(i)
        // }
    }

    useEffect( ()=>{

        const fetchData = async () => {

           let va = await getData(filter)
           setProject(va)
           
        }
        fetchData()
    }, [filter])
    return (
        <>
            <DisplayCategoryProjects data={project} filter={filter}/>
        </>
    );
}

export default CategoryProjects;
import {  Flex, Menu, NavLink, UnstyledButton } from "@mantine/core";
import { Link } from "react-router-dom";
import logo from "./../../../(assets)/logojokernotext.svg"

const NavigationMobile = ({textColor, backgroundColor}) => {

    return (
        // <AppShell header={{height:150}} withBorder="false">

        //     <AppShell.Header style={{backgroundColor:backgroundColor, border:0, padding:20, paddingLeft:30, paddingRight:30, color:textColor, justifyItems:"center"}}  withBorder="false">
                    <Flex direction="column" align="center" style={{width:100+"%", marginTop:10}} >
                        <UnstyledButton component="a" href="/" >
                            {/* <Flex align="center" > */}

                                <img   src={logo} alt="Joker Paris" height={"20px"}/>
                                {/* <h1 className={` ${titiliumWeb.className}`} style={{fontSize:40, paddingLeft:40, color:textColor}} > */}
                                <h1 className={`titilliumWeb `} style={{color:"black", paddingTop:10+"px"}} >
                                    JOKER PARIS 
                                </h1>
                            {/* </Flex> */}
                        </UnstyledButton>
                        <Flex align="center"  fw={"bold"} style={{color:"black", paddingTop:10+"px"}} >
                            <Menu>
                                <Menu.Target>
                                    <NavLink component={Link} to="#need" label="EXPERTISES" autoContrast color={"dark"} ></NavLink>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <NavLink component={Link} to="/expertises/influence" label="INFLUENCE" autoContrast ></NavLink>
                                    <NavLink component={Link} to="/expertises/experientielEtRelationPublique" label="EXPERIENTIEL ET RELATION PUBLIQUE" autoContrast></NavLink>
                                    <NavLink component={Link} to="/expertises/brandContentEtCreation" label="BRAND CONTENT ET CREATION" autoContrast ></NavLink>
                                    <NavLink component={Link} to="/expertises/socialMedia" label="SOCIAL MEDIA" autoContrast></NavLink>

                                </Menu.Dropdown>
                            </Menu>
                            <NavLink component={Link} to="/projets" label="PROJETS" autoContrast></NavLink>
                            <NavLink component={Link} to="/agence" label="AGENCE" autoContrast color={"dark"} ></NavLink>
                            <NavLink href={"mailto:hello@joker-paris.com"} label="CONTACT" autoContrast color={"dark"} ></NavLink>
                        </Flex>
                    </Flex>
        //     </AppShell.Header>
        // </AppShell>
    );
}

export default NavigationMobile;
'use client'
import { CATEGORY } from "../../../category/Category";
import CardsProjects from "../../cardsProjects/CardsProjects";
import PopupProject from "../../popupProjects/PopupProject";
import "./ListProject.css"

import { useEffect, useState } from "react";

const ListCategoryProjects = ({data}) => {
    // const [showModal, setShowModal] = useState( false)
    const [filter, setFilter] = useState("remove")
    const [displayData, setDisplayData] = useState(data)
    const [selectProject, setSelectProject] = useState(data)
    const toggleShowModal = () => {
        document.querySelector("dialog").showModal()
    }

    const onClickBtn = () =>{
        setFilter("remove")
        let val = document.getElementsByClassName('btn-f')
        for(let i of val){
            i.classList.remove('btn-secondary')
        }
    }

    useEffect(()=>{
        if(filter === "remove"){
            setDisplayData(data)
        }else{
            for(let i of CATEGORY){
                if(document.URL.split("/")[document.URL.split("/").length-1] === i.links){
                    setDisplayData(data.filter((value)=>{
                        return value.category.includes(i.name)
                    }))
                    console.log(i.name)
                }
            }
        }
    }, [filter, data])


    return (
        <section className="tw-flex tw-flex-col tw-justify-center  tw-items-center">
            <PopupProject project={selectProject} />
            <div className=" tw-columns-1 tw-row-auto md:tw-columns-3 md:tw-gap-8 tw-items-center tw-h-full tw-w-5/6 tw-justify-center">
                {
                    displayData != null ? displayData.map((v,k)=>{
                        return <div key={k} className="item" >
                            <CardsProjects project={v}  setSelectProject={setSelectProject} toggleShowModal={toggleShowModal}/>
                        </div>
                    }) : ""
                }
            </div>
            {displayData.length === 0 ? <div className="tw-flex tw-flex-col tw-text-center tw-items-center tw-justify-between tw-w-5/6">
                <p>
                    Aucun projet existant pour le filtre : {filter}
                </p>
                <br></br>
                <button className="tw-btn tw-btn-outline tw-rounded-full" onClick={onClickBtn}>Réinitialiser le filtre</button>
            </div> : ""}
            <br></br>
        </section>
    );
}

export default ListCategoryProjects;
import { FaLinkedinIn } from "react-icons/fa";
import {PiInstagramLogo} from "react-icons/pi" 
import { RiTwitterXFill } from "react-icons/ri";
import footerCss from "./FooterLaptop.module.css"
import { UnstyledButton, Button } from "@mantine/core";

const FooterLaptop = () => {
    return (
        <footer className={`${footerCss.footermain} tw-z-20`}>
            <div className={`${footerCss.footerBottom} tw-pt-16`}>
                <h2 className={`${footerCss.footerBottomTitle}  tw-font-bold tw-text-[36px]`}>
                    Jouons cartes sur table.
                </h2>
                <div className={`${footerCss.footerBottomMail} `}>
                    <button component={"a"}  className="tw-btn tw-p-3  titilliumWeb tw-text-center tw-align-middle  tw-rounded-full tw-items-center tw-text-white tw-bg-black tw-text-[20px] tw-pl-12 tw-pr-12"  href={"mailto:hello@joker-paris.com"}>
                        <a href={"mailto:hello@joker-paris.com"} style={{textDecoration:"none", color:"white"}}>
                            hello@joker-paris.com

                        </a>

                    </button>
                </div>
                <br></br>
                <div className="tw-flex tw-flex-row tw-w-full tw-justify-center">
                    <UnstyledButton component={"a"} href={"https://www.instagram.com/jokerparisagence/"} target="_blank">
                        <PiInstagramLogo className={`${footerCss.footerBottomSocialMediaID} tw-rounded-xl`}/>
                    </UnstyledButton>
                    <UnstyledButton component={"a"} href={"https://www.linkedin.com/company/jokerparis/?originalSubdomain=fr"} target="_blank">
                        <FaLinkedinIn  className={`${footerCss.footerBottomSocialMediaID} tw-rounded-xl`}/>
                    </UnstyledButton>
                    <UnstyledButton component={"a"} href={"https://x.com/i/flow/login?redirect_after_login=%2FJokerAgence"} target="_blank">
                        <RiTwitterXFill className={`${footerCss.footerBottomSocialMediaID} tw-rounded-xl`}/>
                    </UnstyledButton>
                </div>
            </div>
        </footer>
    );
}

export default FooterLaptop;
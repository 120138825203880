import { Divider } from "@mantine/core";

import expertisesCSS from "./expertise.module.css"
import CategoryProjects from "../../components/project/categoryProjects/CategoryProjects";
import { ScrollRestoration } from "react-router-dom";
import { useViewportSize } from "@mantine/hooks";

const ExperientielEtRelationPublique = () => {
    const {height, width} = useViewportSize()
    return (
        <div>
            <main className="tw-flex tw-flex-col tw-items-center tw-pb-20">

                <div className=" tw-flex  tw-relative tw-bg-cover tw-bg-no-repeat tw-bg-fixed tw-bg-parallax-experientiel  tw-bg-center tw-h-[600px] tw-w-full tw-mt-0">
                    <div className="tw-absolute tw-z-10 tw-top-[50%] tw-left-[10%]">
                        <h1 className="tw-font-bold tw-text-[20px] tw-text-yellow-600">EXPERIENTIEL ET RELATIONS PUBLIQUES</h1>
                        <h2 className="tw-font-bold tw-text-[38px] tw-text-gray-50">JOUEZ VOTRE MEILLEURE CARTE</h2>
                    </div>
                    {/* <div className="tw-fill-current ">
                        <Image src={socialMediaBack}/>
                    </div> */}

                    {/* <h1 className="tw-font-bold tw-text-[38px]">L{`'`} AGENCE OU LA CREATIVITE SERT <span className="textLinear ">LE RESULTAT</span></h1> */}
                </div>
                <div className="tw-w-5/6 tw-pt-14">
                    <div>
                        <h1 className="tw-font-bold tw-text-[38px] tw-text-black">EXPERIENTIEL ET RELATIONS PUBLIQUES</h1>
                    </div>
                    <br></br>
                    <p className=".comfortaa tw-text-black tw-text-[18px]">
                    En tirant la carte Joker, vous gagnez un partenaire de confiance et un conseiller stratégique à vos côtés. Nous délivrons un accompagnement personnalisé qui s'adapte aux besoins spécifiques de chaque client, même aux contraintes les plus inattendues qui pourraient nécessiter une cellule de crise. Joker vous conduit habilement à travers un éventail d'opportunités où l'expérience prime et marque les esprits. En ce sens, notre équipe s’inspire de vos enjeux stratégiques pour créer un lever de rideau impactant, à votre image. 
                       <br></br>
                        <br></br>
                        Nous vous accompagnons d’entrée de jeu dans votre projet expérientiel, du concept créatif jusqu’à l’organisation, rien n’est laissé au hasard. Lors d’événements physiques ou en ligne, Joker vous accompagne le jour J pour veiller à la réussite d’une expérience maîtrisée jusqu’aux analyses des retombées. 
                       <br></br>
                        <br></br>
                        Besoin de visibilité médiatique ? Nous tissons des liens de confiance avec les médias pour optimiser les retombées en identifiant des opportunités d'engagement. Joker s'engage à concevoir des stratégies sur mesure pour ses clients, que ce soit à travers des kits presse et influence, des projets de cross-branding ou de l’événementiel B2B. Notre équipe s’adapte avec créativité à toutes vos ambitions pour vous dénicher les meilleures opportunités, qu'elles soient mentionnées dans le brief ou nées de notre imagination. Laissez-vous prendre au jeu !
                    </p>

                </div>
                <div className="tw-pt-10 tw-w-5/6">

                {width > 550 ? <div className=" tw-flex tw-mb-10 ">
                        <h1 className={`${expertisesCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}>NOS PROJETS</h1> <Divider orientation="vertical" color={"black"} size={"md"} ml={10} mr={10}>
                        </Divider>
                        {/* <div className="tw-divider tw-divider-neutral tw-divider-horizontal"></div> */}
                        <h1  className="tw-font-bold tw-text-[38px] tw-text-black tw-text-left"> <span className="textLinear"> EXPERIENTIEL ET RELATION PUBLIQUES.</span></h1>
                    </div>: <div className="">
                        <h1 className={`${expertisesCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}>NOS PROJETS</h1> <Divider orientation="vertical" color={"black"} size={"md"} ml={10} mr={10}>
                        </Divider>
                        {/* <div className="tw-divider tw-divider-neutral tw-divider-horizontal"></div> */}
                        <h1  className="tw-font-bold tw-text-[38px] tw-text-black tw-text-left"> <span className="textLinear"> EXPERIENTIEL ET RELATION PUBLIQUES.</span></h1>
                    </div>}
                    <CategoryProjects filter={"experientiel et relation publiques"}/>
                </div>
                   
            </main>
            <ScrollRestoration />
        </div>
    );
}

export default ExperientielEtRelationPublique;
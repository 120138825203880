import { Flex } from "@mantine/core"
import { useElementSize } from "@mantine/hooks"
import anime from "animejs"
import WorkersCards from "../../workersCards/WorkersCards"

const WorkersBannerLaptop = () => {
    const {ref, width, height} = useElementSize()
    const data = 
        [{'name' : 'Tom WELKER', 'status' : 'Directeur Associé', "pics" : "Tom.png"},
        {'name' : 'Arthur BASTIDE', 'status' : 'Directeur Associé', "pics" : "Arthur.png"},
        {'name' : 'Fiona ALIGAND', 'status' : 'Directrice New Business', "pics" : "Fiona.png"},
        {'name' : 'Max JUNOD', 'status' : 'Directeur Entertainment', "pics" : "Max.png"},
        {'name' : 'Melvyn VIEILLARD', 'status' : 'Social Media Manager', "pics" : "Melvyn.png"},
        {'name' : 'Alexandre EVRARD', 'status' : 'Chef de Projet Gaming', "pics" : "Alexandre.png"},
        {'name' : 'Victor HAMCHA', 'status' : 'Consultant IC', "pics" : "Victor.png"}
    ]
    // setInterval(()=>{

    var animation =  anime({
            targets : "#panela",
            translateX : -(3*width),
            duration: 38000, 
            easing : "linear",
            begin : (anim) => {
                
            },
            complete : (anim) => {
                // console.log(anim)
                document.getElementById("panela").style = "left : 100vw;"
                animation.play()
            }
        })

        // eslint-disable-next-line no-unused-expressions
        animation.play()

    // }, 5)

    
    return (
        <div className="tw-w-full tw-overflow-hidden" id="ribbon">

            <Flex direction="row" ref={ref}  justify={"flex-start"} className="tw-w-full ribbonPartners " align={"center"} id="panela">
                {data.map((j,h)=>{
                    return <WorkersCards data={j}  key={h} />
                })}
            </Flex>
        </div>
    );
}

export default WorkersBannerLaptop;
import { AppShell, Flex, Menu, NavLink, UnstyledButton } from "@mantine/core";

import navigationLaptopCSS from "./NavigationLaptop.module.css"

import logo from "./../../../(assets)/logojokernotext.svg"
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavigationLaptop = ({textColor, backgroundColor}) => {
    return (
        <AppShell header={{height:80}} withBorder="false">
            <AppShell.Header style={{backgroundColor:backgroundColor,  color:textColor}}  withBorder="false" >
            {/* <AppShell.Header style={{backgroundColor:backgroundColor,  color:textColor, justifyItems:"center", alignContent:"center"}}  withBorder="true" > */}
                <Container size="xs"  >
                    <Flex direction="row" className={`${navigationLaptopCSS.navigation}`} align="center" justify="space-between">
                        <UnstyledButton component="a" href="/" >
                            <Flex align="center" >

                                <img  className={`${navigationLaptopCSS.navigationPics} `} src={logo} alt="Joker Paris" height={"30px"}/>
                                {/* <h1 className={` ${titiliumWeb.className}`} style={{fontSize:40, paddingLeft:40, color:textColor}} > */}
                                <h1 className={`tw-text-[20px] titilliumWeb tw-text-black tw-m-0`} style={{color:"black", paddingLeft:30+"px"}} >
                                    JOKER PARIS
                                </h1>
                            </Flex>
                        </UnstyledButton>
                        <Flex align="center"  fw={"bold"} >
                            <Menu>
                                <Menu.Target>
                                    <NavLink component={Link} to="#need" label="EXPERTISES" autoContrast color={"dark"} ></NavLink>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <NavLink component={Link} to="/expertises/influence" label="INFLUENCE" autoContrast></NavLink>
                                    <NavLink component={Link} to="/expertises/experientielEtRelationPublique" label="EXPERIENTIEL ET RELATION PUBLIQUE" autoContrast></NavLink>
                                    <NavLink component={Link} to="/expertises/brandContentEtCreation" label="BRAND CONTENT ET CREATION" autoContrast ></NavLink>
                                    <NavLink component={Link} to="/expertises/socialMedia" label="SOCIAL MEDIA" autoContrast></NavLink>

                                </Menu.Dropdown>
                            </Menu>
                            <NavLink component={Link} to="/projets" label="PROJETS" autoContrast></NavLink>
                            <NavLink component={Link} to="/agence" label="AGENCE" autoContrast color={"dark"} ></NavLink>
                            <NavLink href={"mailto:hello@joker-paris.com"} label="CONTACT" autoContrast color={"dark"} ></NavLink>
                        </Flex>
                    </Flex>
                </Container>

                {/* <Flex direction={"row"} justify={"center"} align={"center"}  >
                    <Box w={80+"%"}>

                        <UnstyledButton component="a" href="/" >
                            <Flex direction="row" justify={"space-between"} align={"center"}  >
                                <img src={logo} alt="Joker Paris" className={`${navigationLaptopCSS.navigationPics}`}/>
                                <h1 className={`titiliumWeb`} style={{fontSize:40, paddingLeft:40, color:textColor, width:100+"%"}} >
                                    JOKER PARIS
                                </h1>
                            </Flex>
                        </UnstyledButton>
                    </Box>
                </Flex> */}
            </AppShell.Header>
        </AppShell>
    );
}

export default NavigationLaptop;
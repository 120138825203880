
import { Flex } from "@mantine/core";
import logoJoker from "./../../(assets)/logojokernotext.svg"
import jocardsCss from "./Jocards.module.css"


export const Jocards = ({pics}) => {
    return (
        <div style={{margin:2.5+"rem", zIndex:10}}>
            <div className={jocardsCss.jocardsOutterBound}>
                <Flex direction="column" justify="space-between" className=" tw-justify-between tw-h-full tw-content-between tw-place-content-between" style={{alignContent:"space-between", placeContent:"space-between"}}>
                    <Flex style={{margin:1.25+"rem"}}>
                        <img src={logoJoker} alt="Joker Paris" className={jocardsCss.jocardsOutterLogo}/>
                    </Flex>
                    <Flex  style={{minHeight : 200+"px"}}>
                        <img src={pics} alt="Joker Paris" className={`${jocardsCss.jocardsInnerImage}`}/>
                    </Flex>
                    <Flex  style={{margin:1.25+"rem", float:"right"}}>
                        <img src={logoJoker} alt="Joker Paris" className={jocardsCss.jocardsOutterLogo}/>
                    </Flex>
                </Flex>
            </div>
        </div>
    );
}
import { useEffect } from "react"
import anime from "animejs"

import mainheader from "./BannerLaptop.module.css"
import "./BannerLaptop.css"

import { Jocards } from "../../jocard/Jocards"

import pics1 from "./../../../(assets)/(pics)/1.png"
import pics2 from "./../../../(assets)/(pics)/2.png"
import pics3 from "./../../../(assets)/(pics)/3.png"
import pics4 from "./../../../(assets)/(pics)/4.png"
import pics5 from "./../../../(assets)/(pics)/5.png"
import pics6 from "./../../../(assets)/(pics)/6.png"
import pics7 from "./../../../(assets)/(pics)/7.png"
import pics8 from "./../../../(assets)/(pics)/8.png"
import pics9 from "./../../../(assets)/(pics)/9.png"

const BannerLaptop = () => {
    useEffect(()=>{
        
        anime({
            targets:"#maincards1",
            keyframes : [{
                rotate:'120deg',
                delay:3000,
                duration : 1500,
                opacity:0
            },
            {
                rotate:'240deg',
                delay:3000,
                duration : 1500,
                opacity:0
            },
            {
                rotate:'360deg',
                delay:3000,
                duration : 1500,
                opacity:1
                
            }],
            ease : "easeInOutElastic",
            loop:true,
        })
        anime({
            targets:"#maincards2",
            keyframes : [{
                rotate:'240deg',
                delay:3000,
                duration : 1500,
                opacity:0
            },
            {
                rotate:'360deg',
                delay:3000,
                duration : 1500,
                opacity:1
            },
            {
                rotate:'480deg',
                delay:3000,
                duration : 1500,
                opacity:0
                
            }],
            loop:true,
            ease : "easeInOutElastic"
        })
        anime({
            targets:"#maincards3",
            
            keyframes : [{
                rotate:'360deg',
                delay:3000,
                duration : 1500,
                opacity:1
            },
            {
                rotate:'480deg',
                delay:3000,
                duration : 1500,
                opacity:0
            },
            {
                rotate:'600deg',
                delay:3000,
                duration : 1500,
                opacity:0
                
            }],
            loop:true,
            ease : "easeInOutElastic"
        })

        anime({
            targets : '#panel',
            keyframes:[{
                translateX:'-100vw',
                delay:3000,
                duration : 1500,
                
            }, {
                translateX:'-200vw',
                delay:3000,
                duration : 1500,
                
            },
            {
                translateX:'-300vw',
                delay:3000,
                duration : 1500,
                
            }],
            loop : true,
            ease : "easeInOutElastic"
        },)
    }, [])

    return (
        
        <>
        <div className={`${mainheader.panel} tw-flex tw-flex-col tw-overflow-hidden`}>
            <div className={` tw-w-5/6 tw-z-20`}>
            <div>
        {/* { ((scroll.y > 200) ? <MainNavigation textColor={"black"} backgroundColor={"white"}/> : <MainNavigation textColor={"white"} backgroundColor={"transparent"}/>) */}
            
        
        </div>
               {/* {scroll.y > 200 ? <MainNavigation textColor={"black"} backgroundColor={"white"}/> : <MainNavigation textColor={"white"} backgroundColor={"transparent"}/>} */}
            </div>
            <div className={`${mainheader.cardPanel}`}>

                <div id="maincards1" className={`tw-flex tw-align-middle tw-z-10  tw-justify-center tw-top-56 tw-relative ${mainheader.maincards1}`}>
                    <div className={`${mainheader.poscard1}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics3}/>
                    </div>
                    <div className={`${mainheader.poscard2}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics7}/>
                    </div>
                    <div className={`${mainheader.poscard3}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics1}/>
                    </div>
                </div>
                <div id="maincards2" className={`tw-flex tw-align-middle tw-z-10  tw-justify-center tw-top-56 tw-relative ${mainheader.maincards2}`}>
                    <div className={`${mainheader.poscard1}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics6}/>
                    </div>
                    <div className={`${mainheader.poscard2}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics4}/>
                    </div>
                    <div className={`${mainheader.poscard3}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics2}/>
                    </div>
                </div>
                <div id="maincards3" className={`tw-flex tw-align-middle tw-z-10   tw-justify-center tw-top-56 tw-relative ${mainheader.maincards3}`}>
                    <div className={`${mainheader.poscard1}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics5}/>
                    </div>
                    <div className={`${mainheader.poscard2}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics8}/>
                    </div>
                    <div className={`${mainheader.poscard3}`}>
                        <Jocards className={`${mainheader.jocards}`} pics={pics9}/>
                    </div>
                </div>
            </div>
            <div className={` tw-flex tw-flex-row`} id="panel">
                <div className={`tw-flex tw-flex-row tw-justify-center tw-content-center ${mainheader.pos} ${mainheader.pos1}`}>
                    <div className={`tw-pt-36 tw-font-bold tw-text-[46px]`}>
                        <h1 className="tw-text-center tw-text-white titilliumWeb">Agence créative<br></br>& <span className="textLinear">inspirante.</span></h1>
                    </div>
                </div>
                <div className={`tw-flex tw-flex-row tw-justify-center tw-content-center ${mainheader.pos} ${mainheader.pos2}`}>
                    <div className={`tw-pt-36 tw-font-bold tw-text-[46px] tw-justify-center`}>
                        <h1 className="tw-text-center tw-text-white titilliumWeb">Jouez votre<br></br>meilleure carte.</h1>
                    </div>
                </div>
                <div className={`tw-flex tw-flex-row tw-justify-center tw-content-center ${mainheader.pos} ${mainheader.pos3}`}>
                    <div className={`tw-pt-36 tw-font-bold tw-text-[46px] tw-justify-center`}>
                        <h1 className="tw-text-center tw-text-white titilliumWeb">Vous avez<br></br>les cartes en main.</h1>
                    </div>
                </div>
                <div className={`tw-flex tw-flex-row tw-justify-center tw-content-center ${mainheader.pos} ${mainheader.pos1}`}>
                    <div className={`tw-pt-36 tw-font-bold tw-text-[46px] tw-justify-center`}>
                        <h1 className="tw-text-center tw-text-white titilliumWeb">Agence créative<br></br>& <span className="textLinear">inspirante.</span></h1>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default BannerLaptop;
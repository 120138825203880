

import { Box, Center, Text } from "@mantine/core";
import ListCategoryProjects from "../listCategoryProjects/ListCategoryProjects"

const DisplayCategoryProjects = ({data, filter}) => {
    return (
        <>
            {data.length !== 0 ?<ListCategoryProjects data={data}/> : <Box><Center><Text>Aucun Projet de ce type.</Text></Center></Box>}
        </>
    );
}

export default DisplayCategoryProjects;
import axios from "axios"

export const getProjectsSQL = async () => {
  let r = {data : [], message : "Error"}
  try{

    
    const data =  await axios.get('https://api.joker-paris.com/projet').then((value)=>{
      return value.data
    })
    r.data = data
    r.message = "Success"
    return r
  // let r = []
  }catch(e){
    r.data = e
    r.message = "Error"
    
    
    return r
  }
}

export const getProjectsSQLByCategory = async (value) => {
  let r = {data : [], message : "Error"}
  try{

    
    const data =  await axios.post('https://localhost:3000/api/projets/', {nameCategory : value}).then((value)=>{
      return value
    })
    r.data = data
    r.message = "Success"
  // let r = []
  }catch(e){
    r.data = e
    r.message = e.message
  }
  return r
}


import logoJoker from "./../../../(assets)/logojokernotext.svg"
import jocardsCss from "./WorkersCards.module.css"
import "./WorkersCards.css"

const WorkersCards = ({data}) => {
    return (
        <div className="tw-m-5">
            <div className={jocardsCss.jocardsOutterBound}>
                <div className="tw-flex-col tw-justify-between tw-h-full tw-content-between tw-place-content-between">
                    <div className="tw-flex-row tw-m-5 ">
                        <img src={logoJoker} alt="Joker Paris" className={jocardsCss.jocardsOutterLogo}/>
                    </div>
                    <div className="flex-row tw-min-h-[200px]">
                        <img src={`/(workers)/${data.pics}`} alt="Joker Paris" className={`${jocardsCss.jocardsInnerImage}`}/>
                    </div>
                    <br></br>
                    <div className="flex">

                        <div className="tw-text-left">
                            <div className={`${jocardsCss.workersName} archivoBlack`}>
                                {data.name}
                            </div>
                            <div className={` ${jocardsCss.workersJob} archivoBlack`}>
                                {data.status}
                            </div>
                        </div>
                        <div className="tw-flex-row tw-float-right tw-mr-5 tw-mb-5">
                            <img src={logoJoker} alt="Joker Paris" className={jocardsCss.jocardsOutterLogo}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkersCards;

import { useViewportSize } from "@mantine/hooks";
import NavigationMobile from "./mobile/NavigationMobile";
import NavigationLaptop from "./laptop/NavigationLaptop";
import { useEffect } from "react";

const Navigation = ({textColor, backgroundColor}) => {
    const {height, width} = useViewportSize()

    useEffect(()=>{
        // setWidth(window.innerWidth)
    }, [width])

    return (
        <>
           {
            width > 500 ? <NavigationLaptop  textColor={textColor} backgroundColor={backgroundColor}/>  :   <NavigationMobile textColor={textColor} backgroundColor={backgroundColor}/> 
           } 
        </>
    );
}

export default Navigation;
import { FaLinkedinIn } from "react-icons/fa";
import {PiInstagramLogo} from "react-icons/pi" 
import { RiTwitterXFill } from "react-icons/ri";
import footerCss from "./FooterMobile.module.css"
import { UnstyledButton } from "@mantine/core";

const FooterMobile = () => {
    return (
        <footer className={`${footerCss.footermain} tw-z-20`}>
            <div className={`${footerCss.footerBottom} tw-pt-16`}>
                <h2 className={`${footerCss.footerBottomTitle}  tw-font-bold tw-text-[34px]`}>
                    Jouons cartes sur table.
                </h2>
                <div className={`${footerCss.footerBottomMail} `}>
                    <button  className="tw-btn tw-p-3  titilliumWeb tw-text-center tw-align-middle  tw-rounded-full tw-items-center tw-text-white tw-bg-black tw-text-[20px] tw-pl-12 tw-pr-12">
                        hello@joker-paris.fr
                    </button>
                </div>
                <br></br>
                <div className="tw-flex tw-flex-row tw-w-full tw-justify-center">
                    <UnstyledButton component={"a"} href={"https://www.instagram.com/jokerparisagence/"} target="_blank">
                        <PiInstagramLogo className={`${footerCss.footerBottomSocialMediaID} tw-rounded-xl`}/>
                    </UnstyledButton>
                    <UnstyledButton component={"a"} href={"https://www.linkedin.com/company/jokerparis/?originalSubdomain=fr"}>
                        <FaLinkedinIn  className={`${footerCss.footerBottomSocialMediaID} tw-rounded-xl`} target="_blank"/>
                    </UnstyledButton>
                    <UnstyledButton component={"a"} href={"https://x.com/i/flow/login?redirect_after_login=%2FJokerAgence"}>
                        <RiTwitterXFill className={`${footerCss.footerBottomSocialMediaID} tw-rounded-xl`} target="_blank"/>
                    </UnstyledButton>
                </div>
            </div>
        </footer>
    );
}

export default FooterMobile;
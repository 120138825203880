import { Divider } from "@mantine/core";
// import CategoryProjects from "./../../../components/projets/categoryProjects/CategoryProjects";

import expertisesCSS from "./expertise.module.css"
import CategoryProjects from "../../components/project/categoryProjects/CategoryProjects";
import { ScrollRestoration } from "react-router-dom";

const SocialMedia = () => {
    return (
        <div>
            <main className="tw-flex tw-flex-col tw-items-center tw-pb-20">

                <div className=" tw-flex  tw-relative tw-bg-cover tw-bg-no-repeat tw-bg-fixed tw-bg-center tw-h-[600px] tw-w-full  tw-bg-parallax-social-media tw-mt-0">
                    <div className="tw-absolute tw-z-10 tw-top-[50%] tw-left-[10%]">
                        <h1 className="tw-font-bold tw-text-[20px] tw-text-yellow-600">SOCIAL MEDIA</h1>
                        <h2 className="tw-font-bold tw-text-[38px] tw-text-gray-50">DES CONTENUS VIRAUX ET ENGAGEANTS</h2>
                    </div>
                    {/* <div className="tw-fill-current ">
                        <Image src={socialMediaBack}/>
                    </div> */}

                    {/* <h1 className="tw-font-bold tw-text-[38px]">L{`'`} AGENCE OU LA CREATIVITE SERT <span className="textLinear ">LE RESULTAT</span></h1> */}
                </div>
                <div className="tw-w-5/6 tw-pt-14">
                    <div>
                        <h1 className="tw-font-bold tw-text-[38px] tw-text-black">SOCIAL MEDIA</h1>
                    </div>
                    <br></br>
                    <p className=".comfortaa tw-text-black tw-text-[18px]">
                        Il est primordial que les marques s’investissent sur les réseaux sociaux pour développer leur image et être au plus près du quotidien de leurs communautés. 
                        Dans cette course effrénée pour augmenter son capital sympathie, Joker joue la carte des idées franches et fraîches dans le but d’émerger parmi la masse d’informations quotidiennes. 
                        <br></br>
                        <br></br>
                        La meilleure création de contenu est collective : vous connaissez vos produits et services, nous maîtrisons les codes des plateformes. Ensemble, nous pouvons communiquer efficacement sur les messages souhaités à travers des contenus maîtrisés, viraux et engageants. Notre équipe d’experts vous est dédiée pour créer le storytelling qui servira vos objectifs, à travers une stratégie adaptée et des contenus quotidiens. Avec Joker, vous devenez l’as des conversations organiques, en phase avec les tendances actuelles.
                    </p>

                </div>
                <div className="tw-pt-10 tw-w-5/6">

                    <div className=" tw-flex tw-mb-10 ">
                        <h1 className={`${expertisesCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}>NOS PROJETS</h1> <Divider orientation="vertical" color={"black"} size={"md"} ml={10} mr={10}>
                        </Divider>
                        {/* <div className="tw-divider tw-divider-neutral tw-divider-horizontal"></div> */}
                        <h1  className="tw-font-bold tw-text-[38px] tw-text-black tw-text-left"> <span className="textLinear"> SOCIAL MEDIA.</span></h1>
                    </div>
                    <CategoryProjects filter={"social media"}/>
                </div>
            </main>
            <ScrollRestoration />
        </div>
    );
}

export default SocialMedia;
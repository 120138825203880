import "./WorkersBanner.css"
import WorkersBannerLaptop from "./laptop/WorkersBannerLaptop";
import WorkersBannerMobile from "./mobile/WorkersBannerMobile";
import { useViewportSize } from "@mantine/hooks";

const WorkersBanner = () => {
    const {height, width} = useViewportSize()

// useEffect(()=>{
//     // setWidth(window.innerWidth)
// }, [width])

    return (
        <div>

        <div className="tw-flex tw-justify-around">
                <div className="tw-w-5/6">
                
                    <h1 className="tw-font-bold tw-text-[38px] tw-text-white">NOS MEILLEURS <span className="textLinear ">ATOUTS</span></h1>
                </div>
            </div>
               { width > 550 ? <WorkersBannerLaptop  /> : <WorkersBannerMobile />} 
        </div>
    )
}

export default WorkersBanner;
import { useViewportSize } from "@mantine/hooks";
import { Suspense } from "react";
import FooterLaptop from "./laptop/FooterLaptop";
import FooterMobile from "./mobile/FooterMobile";

const Footer = () => {
    const { height, width} = useViewportSize()
    return (
            <Suspense fallback="<Loading/>">
                { width > 500 ? <FooterLaptop /> : <FooterMobile/> }
            </Suspense>
    );
}

export default Footer;
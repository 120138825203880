export const CATEGORY = [{
    name:"Influence.",
    links : "/expertises/influence",
    filter : "influence"
},{
    name:"Social Média.",
    links : "/expertises/socialMedia",
    filter : "social media"
},{
    name:"Brand content et Création.",
    links : "/expertises/brandContentEtCreation",
    filter : "brand content et creation"
},{
    name:"Expérientiel et Relations publiques.",
    links : "/expertises/experientielEtRelationPublique",
    filter : "experentiel et relations publiques"
}
]
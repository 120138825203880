import { Link } from "react-router-dom";
import { Jocards } from "../../../jocard/Jocards";
import pics from "./../../../../(assets)/(pics)/5.png"
import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import anime from "animejs";
import { Box, Flex } from "@mantine/core";
import { getProjectsSQL } from "./../../../../data/ProjetsSQL";
import PopupMobile from "../../popupProjects/mobile/PopupMobile";
const RandomProjectMobile = () => {
    const [project , setProject] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [selected, setSelected] = useState([])
    const [opened, { open, close }] = useDisclosure(true);

    useEffect( ()=>{
        const fetchData = async () => {
           let va = await getProjectsSQL()
           setProject(va.data.data)
        }
        fetchData().then((value)=>{
            setIsLoad(false)
        })

    }, [])
    

    const getValue = () => {
        
        let random =  Math.floor(Math.random() * project.length)
        setSelected(project[random])
        open()
    }

    useEffect(()=>{
        anime({
            targets : '#topCard',
            keyframes:[{
                rotateZ : "3deg",
                
            }, 
        ],
            direction : 'alternate',
            loop : true
        })
        
    }, [])

    useEffect(()=>{
        
    },[selected])


    return (
        <Flex justify="center" className="tw-bg-black tw-w-full tw-items-center tw-pt-16 tw-pb-10 tw-z-30 " >
            
            {selected.length !== 0 ? <PopupMobile opened={opened} closed={close}   project={selected} /> : ""}
            <Flex direction="column" className="tw-w-4/5" justify={"space-between"}>
                <Box>
                <br></br>
                    <h1 className=" tw-font-bold tw-text-[38px] tw-text-white">DECOUVREZ<span className="textLinear"> NOTRE JEU</span></h1>
                    <br></br>
                    <h3 className="tw-text-white tw-font-bold tw-text-center">Piochez une carte pour découvrir un projet</h3>
                </Box>
                <Flex className="tw-relative tw-h-[400px]" >
                    <div onClick={()=>{getValue()}}>
                        <div className="tw-absolute tw-top-0  tw-z-0 -tw-rotate-3">
                            <Jocards pics={pics}/>
                        </div>
                        <div className="tw-absolute tw-top-0  tw-z-0">
                            <Jocards pics={pics}/>
                        </div>
                        <div className="tw-absolute tw-top-0 tw-rotate-3 tw-z-10"  id="topCard">
                            <Jocards pics={pics} />
                        </div>
                    </div>
                </Flex>
                <div className="tw-columns-1  tw-mt-16 sm:tw-m-auto md:tw-mr-0 tw-text-center md:tw-text-left">
                    <div className="tw-text-white tw-flex tw-flex-col md:tw-items-start tw-justify-center">
                        <button className="tw-btn btndisplay  ">
                            <Link to="/projets">
                                VOIR PLUS DE PROJET
                            </Link>
                        </button>
                    </div>
                </div>
            </Flex>
        </Flex>
    );
}


export default RandomProjectMobile;
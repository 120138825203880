
import { useViewportSize } from "@mantine/hooks";
import BannerLaptop from "./laptop/BannerLaptop";
import BannerMobile from "./mobile/BannerMobile";
import { Flex } from "@mantine/core";
import { useEffect } from "react";

const Banner = () => {
    const {height, width} = useViewportSize()

    useEffect(()=>{
        // setWidth(window.innerWidth)
    }, [width])

    return (
        <Flex>
           {
            width > 500 ? <BannerLaptop />  :   <BannerMobile /> 
           } 
        </Flex>
    );
}

export default Banner;
// import PARTNERS, { getPres, PICS } from "./../components/partners/Partners"
import { ScrollRestoration } from "react-router-dom";
import CategorySquare from "../components/category/categorySquare/CategorySquare";
import Navigation from "../components/navigation/Navigation";
import WorkersDataCarousel from "../components/workers/WorkersDataCarousel";
import "./agence.css"
import { AspectRatio } from "@mantine/core";
import WorkersBanner from "../components/workers/workersBanner/WorkersBanner";
// import videoJoker from "../agence/out"

// import RubanPartners from "./../components/partners/rubanPartners/RubanPartners";

const Agence = () => {
    return (<div className="tw-bg-black">
        <div className="tw-pb-10 tw-pt-20">
            <div className="tw-flex tw-flex-col tw-items-center">
                <div className="tw-w-5/6 "><AspectRatio ratio={16 / 9}>
                <iframe  src="https://www.youtube.com/embed/DiFgugTSqoI?si=0xg8KVTEOJDbZAEb" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe></AspectRatio>
                </div>
                <div className="tw-w-5/6 tw-mt-10 tw-pb-10 ">
    
                    <div>
                        <h1 className="tw-font-bold tw-text-[38px] tw-text-white">L{`'`} AGENCE OU LA STRATEGIE ET LA CREATIVITE SERVENT <span className="textLinear ">LE RESULTAT</span></h1>
                        
                    </div>
                    <br></br>
                    <p>
                    Joker Paris réinvente les règles du jeu : dans notre agence, chaque carte est une arme stratégique où les décisions sont audacieuses et calculées. Que vous jouiez sur le marché local ou que vous visiez les sommets worldwide, notre équipe combine ses expertises complémentaires pour créer des campagnes créatives qui défient les conventions et captivent les esprits. Mais ne vous méprenez pas, derrière notre façade de stratèges implacables se cache une agence à taille humaine, où l'écoute et la transparence ne sont pas des cartes dissimulées, mais les piliers fondamentaux de notre approche.</p>
                    <br></br>
                    <p>
                        <span className="textLinear">
                            La stratégie est notre cœur de métier. 
                        </span> De l’étude de marché jusqu’au choix de vos objectifs, Joker s’adapte à vos projets et vous accompagne dans la réflexion de votre positionnement pour vous différencier avec audace. Ici, la carte de l’observation est de taille ! En révélant votre potentiel, nous déterminons des cibles pertinentes pour transmettre le bon message, au bon moment. 
                    </p>
                    <br></br>
                    <p>
                        Faut-il anticiper, s’adapter ou innover? Joker s’empare de chaque problématique pour y apporter des solutions stratégiques sur mesure, lesquelles sont indispensables pour optimiser votre impact sur le marché. Nous transformons vos objectifs commerciaux en plan de communication où la créativité sert le résultat. Sachez qu’avec Joker, vous restez maître de votre jeu : nous délivrons une analyse avancée avec des rapports de données concrets et vérifiables, sur la base de KPIs prédéfinis. 

                    </p>
                    <p>
                        Dans notre approche stratégique, chaque expertise est une carte maîtresse que nous jouons avec finesse pour affirmer votre présence et maximiser votre impact. Quand allez-vous nous mettre au défi ?
                    </p>

                </div>

                </div>
                {/* <WorkersDataCarousel/> */}
                <WorkersBanner/>
                <CategorySquare/>
                {/* <RubanPartners partner={getPres()}/> */}
        </div>
        
            <ScrollRestoration />
        </div>

    );
}

export default Agence;
import { Suspense } from "react";
import { Outlet } from "react-router";

const Expertises = () => {
    return (
        <div>
            <Suspense fallback={<loading/>}>
                <Outlet/>
            </Suspense>
        </div>
    );
}

export default Expertises;
import { Divider } from "@mantine/core";
import expertisesCSS from "./expertise.module.css"
import CategoryProjects from "../../components/project/categoryProjects/CategoryProjects";
import { ScrollRestoration } from "react-router-dom";


export const Influences = () => {
    return (
        <div>
            
            <main className="tw-flex tw-flex-col tw-items-center tw-pb-20">
                <div className=" tw-flex  tw-relative tw-bg-cover tw-bg-no-repeat tw-bg-fixed tw-bg-parallax-influence   tw-bg-center tw-h-[600px] tw-w-full tw-mt-0">
                    <div className="tw-absolute tw-z-10 tw-top-[50%] tw-left-[10%]">
                        <h1 className="tw-font-bold tw-text-[20px] tw-text-yellow-600">INFLUENCE</h1>
                        <h2 className="tw-font-bold tw-text-[38px] tw-text-gray-50">LES PROFILS LES PLUS PERTINENTS<br></br> POUR VOS CAMPAGNES.</h2>
                    </div>
                    <div className="tw-fill-current ">
                        {/* <Image src={socialMediaBack} alt="Social Media"/> */}
                    </div>

                </div>
                <div className="tw-w-5/6 tw-pt-16">
                    <div>
                        <h1 className="tw-font-bold tw-text-[38px] tw-text-black">INFLUENCE</h1>
                    </div>
                    <br></br>
                    <p className="comfortaa tw-text-black tw-text-[18px]">
                    Alors que les agences d’influenceurs tirent profit de la représentation de talents, Joker n’a qu’une seule mission : recommander les profils les plus pertinents pour les campagnes de nos clients. Nous accompagnons tous types de marques dans leur stratégie d’influence à long terme, en identifiant des créateurs de contenu qui parlent à vos audiences et répondent à vos objectifs. Nous avons la confiance de talents et de célébrités exerçant dans divers secteurs d’activités, ce qui nous permet d’adapter notre stratégie d’influence en fonction des tendances de votre secteur. Considérez Joker comme le prolongement de vos équipes, au sein de votre pôle marketing.  
                       <br></br>
                        <br></br>
                        Grâce à notre lien privilégié avec bon nombre de talents et d’agents, notre équipe vous offre une aisance et une réactivité dans le déploiement de vos campagnes d’influence qui servent vos intérêts, non ceux des KOL. Nous prônons une influence éthique et responsable, il n’est donc pas question de brouiller les cartes. Joker contractualise toutes les activations, vérifie les antécédents de chaque talent et s’assure de la qualité des livrables. Êtes-vous prêt à jouer votre carte Joker ? 
                    </p>

                </div>
                <div className="tw-pt-10 tw-w-5/6">

                    <div className=" tw-flex tw-mb-10 ">
                        <h1 className={`${expertisesCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}>NOS PROJETS</h1> <Divider orientation="vertical" color={"black"} size={"md"} ml={10} mr={10}>
                        </Divider>
                        {/* <div className="tw-divider tw-divider-neutral tw-divider-horizontal"></div> */}
                        <h1  className={`${expertisesCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}> <span className="textLinear"> INFLUENCE.</span></h1>
                    </div>
                    <CategoryProjects filter={"influence"}/>
                </div>
            </main>
            <ScrollRestoration />
        </div>
    );
}

export default Influences;
'use client'
import listeCSS from "./ListProject.module.css"

import { useEffect, useState } from "react";
import CardsProjects from "../../cardsProjects/CardsProjects";
import FilterProjects from "../../filterProjects/FilterProjects";
import {  Divider, Grid, Group } from "@mantine/core";

// import { PopupProjects } from "../../popupProjects/PopupProjects";
// import PopupProjets2 from "../../popupProjects/PopupProjets2";
// import PopupProject3 from "../../popupProjects/PopupProject3";

const ListProjects = ({data}) => {
    // const [showModal, setShowModal] = useState( false)
    const [filter, setFilter] = useState("remove")
    const [displayData, setDisplayData] = useState(data)
    const [selectProject, setSelectProject] = useState(data[1])
    const onClickBtn = () =>{
        setFilter("remove")
        let val = document.getElementsByClassName('btn-f')
        for(let i of val){
            i.classList.remove('btn-secondary')
        }
    }
    
    useEffect(()=>{
        if(filter === "remove"){
            setDisplayData(data)
        }else{
            data.filter((value)=>{
                if( value !== undefined && value.category !== undefined ){ return value.category.filter((value)=>{
                    return value.nameCategory === filter
                }) }
             })
            setDisplayData(data.filter((value) => {
                if( value !== undefined && value.category !== undefined ){ return value.category.find((value)=>{
                    return value.nameCategory === filter
                }) }
             }))
        }
    }, [filter, data])


    return (
        <section className="tw-flex tw-flex-col tw-w-full" >
            <div className="tw-mt-20 tw-mb-10 ">
                <Group >
                
                    <h1 className={`${listeCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}>NOS PROJETS</h1>
                    <Divider orientation="vertical" size={"xl"} color="black"/>

                
                    <h1  className={`${listeCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}>On annonce la <span className="textLinear">couleur.</span></h1>
                </Group>
            </div>
            
            <br></br>
            <FilterProjects setFilter={setFilter}/>
            <br></br>
            {/* <div className=" tw-columns-1 tw-row-auto md:tw-columns-3 md:tw-gap-8 tw-items-center tw-h-full tw-w-5/6 tw-justify-center"> */}
            {/* <div className={` grid md:grid items-center`}> */}
                {/* {
                    console.log(data)
                } */}
                <Grid  grow>

                {
                    data != null ? displayData.map((v,k)=>{
                        return <Grid.Col key={k} span={{base:4,md:4, xs:12, sm:12,}}>
                            <CardsProjects project={v}  setSelectProject={setSelectProject} />
                        </Grid.Col>
                    }) : ""
                }
                </Grid>
            {/* </div> */}
            {displayData.length === 0 ? <div className="tw-flex tw-flex-col tw-text-center tw-items-center tw-justify-between tw-w-5/6">
                <p>
                    Aucun projet existant pour le filtre : {filter}
                </p>
                <br></br>
                <button className="tw-btn tw-btn-outline tw-rounded-full" onClick={onClickBtn}>Réinitialiser le filtre</button>
            </div> : ""}
            <br></br>
        </section>
    );
}

export default ListProjects;
import { Link } from "react-router-dom";
import { Jocards } from "../../../jocard/Jocards";
import pics from "./../../../../(assets)/(pics)/5.png"
import arrow from "./../../../../(assets)/arrow.svg"
import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import anime from "animejs";
import { Flex } from "@mantine/core";
import { getProjectsSQL } from "./../../../../data/ProjetsSQL";
import PopupLaptop from "../../popupProjects/laptop/PopupLaptop";

const RandomProjectLaptop = () => {
    const [project , setProject] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [selected, setSelected] = useState([])
    const [opened, { open, close }] = useDisclosure(true);

    useEffect( ()=>{
        const fetchData = async () => {
           let va = await getProjectsSQL()
           setProject(va.data.data)
        }
        fetchData().then((value)=>{
            setIsLoad(false)
        })

    }, [])
    

    const getValue = () => {
        
        let random =  Math.floor(Math.random() * project.length)
        setSelected(project[random])
        open()
    }

    useEffect(()=>{
        anime({
            targets : '#topCard',
            keyframes:[{
                rotateZ : "3deg",
                
            }, 
        ],
            direction : 'alternate',
            loop : true
        })
        
    }, [])

    useEffect(()=>{
        
    },[selected])


    return (
        <Flex justify="center" className="tw-bg-black tw-flex tw-flex-col tw-w-full tw-items-center tw-pt-16 tw-pb-10 tw-z-20 " >
            
            <div className="tw-w-4/5 tw-border-white ">
            {selected.length !== 0 ? <PopupLaptop opened={opened} closed={close}   project={selected} /> : ""}
                <br></br>
                <Flex className="" >
                    <h1 className=" tw-font-bold tw-text-[38px] tw-text-white">DECOUVREZ<span className="textLinear"> NOTRE JEU</span></h1>
                </Flex>
                <br></br>
                <br></br>
                <br></br>
                {/* <div className="items-center  "> */}
                    <div className="">
                        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center">

                            <div className="tw-columns-1 " style={{cursor:"pointer"}}>
                                <div className="tw-relative" onClick={()=>{getValue()}}>
                                    <div className="tw-absolute tw-top-0  tw-z-0 -tw-rotate-3">
                                        <Jocards pics={pics}/>
                                    </div>
                                    <div className="tw-absolute tw-top-0  tw-z-0">
                                        <Jocards pics={pics}/>
                                    </div>
                                    <div className="tw-absolute tw-top-0 tw-rotate-3 tw-z-10"  id="topCard">
                                        <Jocards pics={pics} />
                                    </div>
                                </div>
                            </div>
                            <div className="tw-columns-1 tw-w-2/4 tw-mt-16 sm:tw-m-auto md:tw-mr-0 tw-text-center md:tw-text-left">
                                <div className="tw-text-white tw-flex tw-flex-col md:tw-items-start tw-justify-center">
                                     <div className="md:tw-relative md:-tw-left-28 tw-hidden md:tw-block">
                                            <img src={arrow} alt="La pointe de la flèche"/>
                                    </div> 
                                    <h3 className="tw-text-white tw-font-bold ">Piochez une carte pour découvrir un projet</h3>
                                    <br></br>
                                    <br></br>
                                    <button className="tw-btn btndisplay  ">
                                        <Link to="/projets">
                                            VOIR PLUS DE PROJET
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </Flex>
    );
}

export default RandomProjectLaptop;
import { Divider } from "@mantine/core";

import expertisesCSS from "./expertise.module.css"
import CategoryProjects from "../../components/project/categoryProjects/CategoryProjects";
import { ScrollRestoration } from "react-router-dom";

const BrandContent = () => {
    return (
        <div>
            <main className="tw-flex tw-flex-col tw-items-center tw-pb-20">

                <div className=" tw-flex  tw-relative tw-bg-cover tw-bg-no-repeat tw-bg-fixed tw-bg-parallax-brand-content tw-bg-center tw-h-[600px] tw-w-full tw-mt-0">
                    
                    <div className="tw-absolute tw-z-10 tw-top-[50%] tw-left-[10%]">
                        <h1 className="tw-font-bold tw-text-[20px] tw-text-yellow-600">BRAND CONTENT & CRÉATION</h1>
                        <h2 className="tw-font-bold tw-text-[38px] ">DES IDEES AUDACIEUSES <br></br> POUR REPONDRE A VOS BESOINS.</h2>
                    </div>
                    {/* <div className="tw-fill-current ">
                        <Image src={socialMediaBack}/>
                    </div> */}

                    {/* <h1 className="tw-font-bold tw-text-[38px]">L{`'`} AGENCE OU LA CREATIVITE SERT <span className="textLinear ">LE RESULTAT</span></h1> */}
                </div>
                <div className="tw-w-5/6 tw-pt-14">
                    <div>
                        <h1 className="tw-font-bold tw-text-[38px] tw-text-black">BRAND CONTENT & CRÉATION</h1>
                    </div>
                    <br></br>
                    <p className=".comfortaa tw-text-black tw-text-[18px]">
                    Entrez dans le jeu ! Chez Joker, nous jouons habilement nos atouts stratégiques et créatifs pour placer votre marque en haut de la pile, attirant ainsi tous les regards. Que ce soit pour des formats courts ou des vidéos institutionnelles, nous excel­lons dans l'art de créer des mécaniques envoûtantes qui transcendent le simple contenu. Chez Joker, chaque expertise est mise à contribution pour façonner une stratégie éditoriale cohérente qui rebat les cartes. Grâce à nos graphistes, réalisateurs et rédacteurs, nous vous aidons à devenir le média de vos valeurs en produisant des contenus informatifs, éducatifs ou divertissants, mais toujours qualitatifs.
                       <br></br>
                        <br></br>
                        Notre production de contenu est le jeu parfait de nos idées les plus audacieuses, soigneusement distribué pour répondre à vos besoins. Chaque stratégie créative apporte de la valeur au public ciblé à travers un univers de marque identifiable. Joker devient créateur d’émotions pour renforcer les liens avec votre communauté. En travaillant votre identité, vous obtenez des conversations amplifiées, du brand love et une notoriété remarquée. Avec Joker, soyez assuré d’avoir toutes les cartes en main dans votre stratégie de contenu.
                    </p>

                </div>
                <div className="tw-pt-10 tw-w-5/6">

                    <div className=" tw-flex tw-mb-10 ">
                        <h1 className={`${expertisesCSS.titre} tw-font-bold tw-text-[38px] tw-text-left`}>NOS PROJETS</h1><Divider orientation="vertical" color={"black"} size={"md"} ml={10} mr={10}>
                        </Divider><h1  className="tw-font-bold tw-text-[38px] tw-text-black tw-text-left"> <span className="textLinear">BRAND CONTENT ET CREATION.</span></h1>
                    </div>
                    <CategoryProjects filter="brand content et creation"/>
                </div>
            </main>
            <ScrollRestoration />
        </div>
    );
}

export default BrandContent;
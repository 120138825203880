'use client'

import filterProjectsCSS from "./FilterProjects.module.css"
import { useState } from "react";
import { Divider, Grid } from "@mantine/core";
import { CATEGORY } from "../../category/Category";

const FilterProjects = ({setFilter, filter}) => {
    const [filterValue, setFilterValue] = useState([])

    const onClickBtn = (event) =>{
        setFilter(event.target.name)
        let val = document.getElementsByClassName('btn-f')
        for(let i of val){
            i.classList.remove('btn-secondary')
        }
        if(event.target.name !== "remove"){
            event.target.classList.add('btn-secondary')
        }
    }

    return (
        <article className="tw-w-full">
            <div className="tw-mb-5">
                <h2 className={`${filterProjectsCSS.titre} tw-font-bold tw-text-black`}>
                    FILTRER LES <span className="textLinear ">PROJETS</span>
                </h2>
            </div>
            <Grid grow>

            {/* <div className="tw-grid tw-grid-flow-col md:tw-auto-cols-auto tw-grid-flow-row "> */}
                {CATEGORY.map((v, k)=>{
                    return <Grid.Col key={k} span={"content"}>

                        <button className={`tw-btn tw-btn-outline btn-f ${filterProjectsCSS.btnFilter}`} name={v.filter} onClick={onClickBtn} >{v.name}</button>
                    </Grid.Col > 
                })}
                <Grid.Col  span={"content"}>
                    <button className={`tw-btn tw-btn-outline ${filterProjectsCSS.btnFilter}`} name="remove" onClick={onClickBtn}>X</button>
                </Grid.Col>
            </Grid>
            {/* </div> */}
            <Divider my={"md"}/>
        </article>
    );
}

export default FilterProjects;
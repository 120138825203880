
import { Link } from "react-router-dom";
import { CATEGORY } from "../Category";
import categoryCSS from "./CategorySquare.module.css"
import { Flex } from "@mantine/core";

const CategorySquare = () => {
    return ( 
        <div className="tw-flex tw-pt-10 tw-pb-[100px] tw-justify-around tw-bg-black tw-w-full">
            <div className="tw-w-5/6">
            <Flex>

                <h1 className="tw-font-bold tw-text-[38px] tw-text-white">VOUS AVEZ <span className="textLinear">LES CARTES EN MAIN</span></h1>
            </Flex>
                <br></br>
                <br></br>
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 ">
                    <Link to="/agence" className="md:tw-col-span-2 tw-no-underline">
                        <div className={`${categoryCSS.category} tw-text-center tw-flex  tw-text-[38px] titilliumWeb `}>
                            Stratégie.
                        </div>
                    </Link>
                    {
                        CATEGORY.map((v,k)=>{
                            return  <Link to={v.links} key={k} className="tw-no-underline">
                                        <div className={`${categoryCSS.category} tw-text-center tw-flex  tw-text-[38px] titilliumWeb`}>
                                            {v.name}
                                    </div>
                                </Link>
                        }) 
                    }
                </div>
            </div>
        </div>
    );
}

export default CategorySquare;
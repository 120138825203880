
import {  Box , Card, Image,} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import PopupProject from "../popupProjects/PopupProject";

import cardsProjectCSS from "./CardsProjects.module.css"

const CardsProjects = ({project, setSelectProject, toggleShowModal}) => {
    
  const [opened, { open, close }] = useDisclosure(false);
    const selectProject = () => {
            setSelectProject(project)
            open()
    }

    return (
        <>
            <PopupProject project={project} opened={opened}  closed={close}/>
            
            <Card withBorder mb={"md"} onClick={selectProject} maw={450}>
                <Card.Section >
                    <Image src={project.path}  alt={project.name} className="tw-w-full tw-h-full tw-opacity-55 hover:tw-ease-linear hover:tw-opacity-100"/>
                    <Box p={"md"}>
                    {/* <button className="btn" onClick={()=>{selectProject()}}>open modal</button> */}
                    {/* <h4>{project.name}</h4> */}
                    <h2 className={`${cardsProjectCSS.titre} tw-opacity-85 hover:tw-ease-linear hover:tw-opacity-100`} >{project.nameProject}</h2>
                    <p className={`${cardsProjectCSS.titre} tw-opacity-85 hover:tw-ease-linear hover:tw-opacity-100`} >{project.name}</p>
                    </Box>
                </Card.Section>
            </Card>
        
        </>
    );
}

export default CardsProjects;
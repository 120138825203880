import "./popup.css"
import PopupLaptop from "./laptop/PopupLaptop";
import PopupMobile from "./mobile/PopupMobile";
import { useViewportSize } from "@mantine/hooks";

// import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min.js";

const PopupProject = ({project, opened,  closed}) => {
    const {height, width} = useViewportSize()

    // useEffect(()=>{
    //     // setWidth(window.innerWidth)
    // }, [width])

    return (
                 width > 550 ? <PopupLaptop  project={project} opened={opened}  closed={closed} /> : <PopupMobile  project={project} opened={opened}  closed={closed}/> 
    )
}
        
export default PopupProject;
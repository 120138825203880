"use server";
import ListProjects from "./listProjects/ListProjects";
import { getProjectsSQL } from "../ProjetsSQL";
import { useEffect, useState } from "react";




const FullListProjects =   () => {
    const [project , setProject] = useState([])

    useEffect( ()=>{
        const fetchData = async () => {
           let va = await getProjectsSQL()
           setProject(va.data.data)
        }
        fetchData()
    }, [])
     
    return (
        <>
            {/* <RubanPartners/> */}
            <ListProjects data={project}/>
        </>
    );
}

export default FullListProjects;